[data-layout='true'] {
    max-width: 100% !important;
}

.textFormFieldBlock {
    label {
        padding: 0;
        width: 1px;
        height: 1px;
        border: 0;
        white-space: nowrap;
        text-transform: none;
        position: absolute;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        -webkit-clip-path: inset(50%);
    }

    input {
        margin-bottom: 2rem !important;

        font-family: $font-family-bold !important;
        font-size: 1.8rem !important;
        font-weight: $font-weight-bold !important;
        line-height: 100% !important;
        // text-align: center !important;
        color: $white !important;
        background-color: $black !important;
        border: 0 !important;

        &::placeholder {
            color: $grey-60;
        }
    }
}

.consentBlock div input {
    accent-color: $purple !important;
}

.submitButton {
    padding: 2rem 3.2rem !important;
    width: 100% !important;
    font-family: $font-family-bold !important;
    font-weight: $font-weight-bold !important;
    font-size: 1.6rem !important;
    line-height: 100% !important;
    text-align: center !important;
    color: $black !important;
    background-color: $white !important;
    border: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    position: relative;
    z-index: 0;
    transition: color $transition-ease-out-expo;

    @include media(m) {
        padding: 2.2rem 3.2rem !important;
        width: auto !important;
        font-size: 2rem !important;
    }

    &::after {
        content: '';
        width: 0;
        height: 100%;
        background-color: $violet;
        position: absolute;
        inset: 0;
        z-index: -1;
        transition: width $transition-ease-out-expo;
    }

    @media (hover: hover) {
        &:hover {
            color: $black;

            &::after {
                width: 100%;
            }
        }
    }
}

.onFormSubmittedFeedback {
    width: 100% !important;
    background-color: $black !important;
}

.onFormSubmittedFeedbackMessage {
    font-family: $font-family-bold !important;
    font-size: 1.8rem !important;
    font-weight: $font-weight-bold !important;
    line-height: 100% !important;
    color: $white !important;
}
