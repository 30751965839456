html,
body {
    padding: 0;
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
    -webkit-font-smoothing: antialiased;
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6rem;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

*::selection {
    color: $black;
    background-color: $violet;
}

*:focus-visible {
    outline-offset: 4px;
    outline: $purple auto 2px;
}

.no-scroll {
    @include media-max(l) {
        overflow: hidden;
    }
}

.sr-only {
    padding: 0;
    width: 1px;
    height: 1px;
    border: 0;
    white-space: nowrap;
    text-transform: none;
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    -webkit-clip-path: inset(50%);
}

.PageTransition {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;

    &__Logo {
        width: 50vw;
        max-width: 300px;
    }
}
